

























import { Component, Emit, Prop } from "vue-property-decorator";
import BaseView from "@/views/BaseView.vue";
import BaseForm from "@/components/dialog/BaseForm.vue";
import CsRequestStates, { CsRequestState } from "@/consts/CsRequestStates";
import CsApi, { CreateRequestParams } from "@/apis/CsApi";
import CsRequestItem from "@/views/models/admin/CsRequestItem";

@Component({
  components: {
    BaseForm,
  },
})
export default class EditForm extends BaseView {
  @Emit()
  private cancel() {
    return false;
  }

  @Emit()
  private submit(item: CsRequestItem) {
    return item;
  }

  @Prop()
  params!: CreateRequestParams;

  private api = new CsApi();

  private request!: CreateRequestParams;

  private states!: CsRequestState[];

  private created() {
    this.states = CsRequestStates.filter((state) => state.value !== "");

    this.request = { ...this.params, state: "finished" };
  }

  private async save() {
    this.isConnecting = true;
    this.$showProgress();

    try {
      const repo = await this.api.createRequests(this.request);
      this.submit(repo.data);
    } catch (e) {
      this.handleError(e);
    }

    this.isConnecting = false;
    this.$hideProgress();
  }

  private facilitys: any = [];
}
