


























































































































































































































import { Component } from "vue-property-decorator";
import BaseView from "@/views/BaseView.vue";
import EditForm from "./forms/EditForm.vue";
import CreateForm from "./forms/CreateForm.vue";
import OrgSearch from "@/components/ui/OrgSearch.vue";
import OrgCategory from "@/components/ui/OrgCategory.vue";
import { Org } from "@/models/Org";
import CsApi, { CreateRequestParams } from "@/apis/CsApi";
import {
  CsRequestCategory,
  CsStateType,
  CS_TYPE_FINISHED,
  CS_TYPE_IMPOSSIBLE,
  CS_TYPE_PROGRESS,
  CS_TYPE_RECEIPT,
} from "@/models/Cs";
import CsRequestItem from "@/views/models/admin/CsRequestItem";
import csRequestStates from "@/consts/CsRequestStates";
import Pagenation from "@/models/Pagenation";

@Component({
  components: {
    EditForm,
    OrgSearch,
    OrgCategory,
    CreateForm,
  },
})
export default class Main extends BaseView {
  $refs!: {
    orgSearch: InstanceType<typeof OrgSearch>;
  };

  private api = new CsApi();

  private selectedOrg!: Org;
  private searchHandlerName = "";
  private selectedState: number[] = [];
  private selectOrgCategory = 3;

  private isEditing = false;
  private isCreate = false;

  private request: CreateRequestParams = {
    org_id: 0,
    remark: "",
    content: "",
    state: "receipt",
    cs_category_id: 0,
  };

  private pagenation = new Pagenation();

  private states = csRequestStates;

  private csCategories: CsRequestCategory[] = [];

  private headers = [
    { text: "ID", value: "id" },
    {
      text: "시설명",
      value: "org.name",
    },
    {
      text: "문의유형",
      value: "category.name",
      sortable: false,
    },
    {
      text: "내용",
      value: "content",
      sortable: false,
    },
    { text: "접수일", value: "date" },
    { text: "상태", value: "state" },
    { text: "담당자", value: "handler[0].user.name" },
    { text: "기능", value: "actions", sortable: false },
    { text: "", value: "data-table-expand" },
  ];

  private items: CsRequestItem[] = [];
  private showItems: CsRequestItem[] = [];
  private editedItem!: CsRequestItem;

  private created() {
    this.loadCsCategories();
    this.loadRequest(1);
  }

  private async loadCsCategories() {
    try {
      const repo = await this.api.getCsCategories();
      console.log(repo.data);
      this.csCategories = [...repo.data];
    } catch (e) {
      this.handleError(e);
    }
  }

  private async loadRequest(page: number) {
    if (this.isConnecting) return;
    this.isConnecting = true;

    try {
      const params = {
        org_id: this.selectedOrg?.id,
        per_page: 30,
        page,
      };

      const repo = await this.api.getCsRequests(params);

      repo.data.per_page = Number(repo.data.per_page);

      this.pagenation = repo.data;
      this.items = [...repo.data.data];
      this.createShowItems();
    } catch (e) {
      this.handleError(e);
    }
    this.isConnecting = false;
  }

  private onClickRequestSave() {
    if (this.isConnecting) return;

    if (!this.selectedOrg) {
      this.$alert("알림", "시설을 선택해주세요.");
      return;
    }

    this.request.org_id = this.selectedOrg.id;

    this.isCreate = true;
  }

  private saveRequest(item: CsRequestItem) {
    this.isCreate = false;
    this.pubhCreateRequest(item);
    this.createShowItems();
    this.$showSnack("문의가 등록되었습니다.");
  }

  private pubhCreateRequest(item: CsRequestItem) {
    const category = this.csCategories.find(
      (category) => category.id === item.cs_category_id
    );
    console.log(category, item.cs_category_id);
    item.date = item.date.substring(0, 10);

    if (category) {
      item.category = category;
    }

    this.items = [item, ...this.items];
  }

  private getStateColor(state: CsStateType) {
    switch (state) {
      //접수
      case CS_TYPE_RECEIPT:
        return "orange";

      //처리중
      case CS_TYPE_PROGRESS:
        return "lightgray";

      //완료
      case CS_TYPE_FINISHED:
        return "green";

      //처리불가
      case CS_TYPE_IMPOSSIBLE:
        return "red";

      default:
        return "";
    }
  }

  private onClickEdit(item: CsRequestItem) {
    this.isEditing = true;
    this.editedItem = { ...item };
  }

  private onSelectOrg(org: Org) {
    this.selectedOrg = { ...org };
    this.loadRequest(1);
  }

  private updateRequest(requestItem: CsRequestItem) {
    this.isEditing = false;
    console.log(requestItem);
    const items = [...this.items];
    const idx = items.findIndex((item) => item.id === requestItem.id);

    items[idx] = { ...requestItem };

    this.items = items;

    this.createShowItems();

    this.$showSnack("문의가 수정되었습니다.");
  }

  private onClickRequestDelete(id: number) {
    this.$confirm("알림", "삭제하시겠습니까?", (result: boolean) => {
      result && this.deleteRequest(id);
    });
  }

  private async deleteRequest(id: number) {
    if (this.isConnecting) return;

    this.isConnecting = true;
    this.$showProgress();

    try {
      await this.api.deleteRequest(id);

      const items = [...this.items];
      const idx = items.findIndex((item) => item.id === id);

      items.splice(idx, 1);

      this.items = items;
      this.createShowItems();
      this.$showSnack("해당 문의가 삭제되엇습니다.");
    } catch (e) {
      this.handleError(e);
    }

    this.isConnecting = false;
    this.$hideProgress();
  }

  private createShowItems() {
    const items = this.items.filter((item) => {
      const state = this.selectedState;
      const stateIdx = this.states.findIndex((val) => val.value === item.state);
      const name = item.handler[0].user.name;
      const keyword = this.searchHandlerName;

      if (state[0] > 0 && state.indexOf(stateIdx) < 0) return;
      if (keyword !== "" && name.indexOf(keyword) < 0) return;

      return item;
    });

    this.showItems = [...items];
  }

  private onClickOrgName(item: CsRequestItem) {
    this.selectedOrg = item.org;
    this.loadRequest(1);
  }

  private onClickGoCenterDetail(item: CsRequestItem) {
    //추후에 org_center table id값으로 변경 필요.
    window.open(
      `https://admin.i-erp.info/admin/center/${item.org.org_center?.center_id}/home`
    );
  }

  private onSelectState() {
    const states = this.selectedState;
    if (states[states.length - 1] === 0) {
      this.selectedState = [0];
    } else {
      this.selectedState = states.filter((val) => val > 0);
    }

    this.createShowItems();
  }

  private onSelectOrgCategory(val: number) {
    this.selectOrgCategory = val;
  }
}
