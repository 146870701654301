import Vue from "vue";
import { CsStateType } from "@/models/Cs";
import CsRequestItem from "@/views/models/admin/CsRequestItem";

export default class CsApi {
  root = "cses";

  getCsRequests(params: any) {
    return Vue.axios.get(`${this.root}/requests`, {
      params,
    });
  }

  getCsCategories() {
    return Vue.axios.get(`${this.root}/categories`);
  }

  createRequests(params: CreateRequestParams) {
    return Vue.axios.post(`${this.root}/requests`, params);
  }

  updateRequests(params: CsRequestItem) {
    return Vue.axios.put(`${this.root}/requests/${params.id}`, params);
  }

  deleteRequest(id: number) {
    return Vue.axios.delete(`${this.root}/requests/${id}`);
  }
}

export type CreateRequestParams = {
  state: CsStateType | "";
  content: string;
  cs_category_id: number;
  org_id: number;
  remark: string;
};
