export const CS_TYPE_RECEIPT = "receipt";
export const CS_TYPE_PROGRESS = "progress";
export const CS_TYPE_FINISHED = "finished";
export const CS_TYPE_IMPOSSIBLE = "impossible";

export type CsRequest = {
  id: number;
  cs_category_id: number;
  org_id: number;
  request_user_id: number;
  date: string;
  state: CsStateType;
  finished_at: string;
  remark: string;
};

export type CsStateType =
  | typeof CS_TYPE_RECEIPT
  | typeof CS_TYPE_PROGRESS
  | typeof CS_TYPE_FINISHED
  | typeof CS_TYPE_IMPOSSIBLE;

export type CsRequestCategory = {
  id: number;
  name: string;
};

export type CsRequestHandler = {
  id: number;
  cs_request_id: number;
  cs_user_id: number;
};
