














































import { Component, Emit, Prop } from "vue-property-decorator";
import BaseView from "@/views/BaseView.vue";
import BaseForm from "@/components/dialog/BaseForm.vue";
import ValidationTextField from "@/components/ui/ValidationTextField.vue";
import ValidationSelect from "@/components/ui/ValidationSelect.vue";
import ValidationTextarea from "@/components/ui/ValidationTextarea.vue";
import CsRequestItem from "@/views/models/admin/CsRequestItem";
import CsRequestStates, { CsRequestState } from "@/consts/CsRequestStates";
import CsApi from "@/apis/CsApi";

@Component({
  components: {
    BaseForm,
    ValidationTextField,
    ValidationSelect,
    ValidationTextarea,
  },
})
export default class EditForm extends BaseView {
  @Emit()
  private cancel() {
    return false;
  }

  @Emit()
  private submit() {
    return this.editedItem;
  }

  @Prop()
  editedItem!: CsRequestItem;

  private api = new CsApi();
  private states!: CsRequestState[];

  private created() {
    this.states = CsRequestStates.filter((state) => state.value !== "");
  }

  private async save() {
    this.isConnecting = true;
    this.$showProgress();

    try {
      await this.api.updateRequests(this.editedItem);
      this.submit();
    } catch (e) {
      this.handleError(e);
    }

    this.$hideProgress();
    this.isConnecting = false;
  }
}
